import { ButtonGroup, IconButton } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { PortalIcon } from '../../../../common/components/PortalIcon';
import { mdiTrashCanOutline, mdiPencilOutline } from '@mdi/js';
import { history } from '../../../../common/history/history';
import { getCtxMappingEditRoute } from '../../../routes/ContextMappingDrawerRoute';
import { ContextMappingType } from '../../../types/types';

export const contextMappingTableButtonsAccessor = (contextId: string) => {
  return (
    <ButtonGroup flexDir='row' justifyContent='flex-end' size='sm' width='full'>
      <IconButton
        variant='ghostColorOnHover'
        colorScheme='danger'
        aria-label='edit context mapping'
        icon={<PortalIcon path={mdiPencilOutline} />}
        onClick={() =>
          history.push({
            pathname: getCtxMappingEditRoute(contextId),
            search: history.location.search,
          })
        }
      />
      <IconButton
        variant='ghostColorOnHover'
        colorScheme='danger'
        aria-label='delete context mapping'
        icon={<PortalIcon path={mdiTrashCanOutline} />}
      />
    </ButtonGroup>
  );
};

type GetTableOptions = {
  withXmCloudContext: boolean;
};

export const getTableOptions = ({ withXmCloudContext }: GetTableOptions) => {
  return {
    columns: [
      {
        Header: <T _str='Context ID' />,
        accessor: 'contextId',
      },
      ...(withXmCloudContext
        ? [
            {
              Header: <T _str='Edge Endpoint' />,
              accessor: 'edgeEndpoint',
            },
          ]
        : []),
      {
        Header: <T _str='Personalize Tenant' />,
        accessor: 'personalizeTenant',
      },
      {
        Header: <T _str='Search Tenant' />,
        accessor: 'searchTenant',
      },
      {
        id: 'context=-mapping-table-buttons',
        Header: '',
        accessor: ({ contextId }: ContextMappingType) =>
          contextMappingTableButtonsAccessor(contextId),
        disableSortBy: true,
      },
    ],
  };
};
