/* eslint-disable no-console */
import { Heading, Flex, ButtonGroup, Button } from '@chakra-ui/react';
import { T } from '@transifex/react';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { getTableOptions } from './helpers/contextMappingTableHelper';
import { DataTable } from '../../../common/components/DataTable';
import { Feature } from '../../../../lib/featureFlags/features';
import { useMainState } from '../../../main/context';
import { PortalIcon } from '../../../common/components/PortalIcon';
import { mdiPlus } from '@mdi/js';
import {
  ContextMappingDrawerRoute,
  ctxMappingCreateRoute,
  getCtxMappingEditRoute,
} from '../../routes/ContextMappingDrawerRoute';
import { AddEditContextMappingDrawer } from '../drawer/AddEditContextMappingDrawer';
import { history } from '../../../common/history/history';
import { ContextMappingType } from '../../types/types';

const Header = ({ children }: { children: ReactNode }) => (
  <Flex
    flexDirection={['column', 'column', 'row']}
    justifyContent='space-between'
    marginBottom={12}
    gap={[6, 6, 0]}
  >
    {children}
  </Flex>
);

export const ContextMappingTable = () => {
  const { withXmCloudContext } = useMainState();

  const options = useMemo(
    () =>
      getTableOptions({
        withXmCloudContext: withXmCloudContext || false,
      }),
    [withXmCloudContext],
  );
  const onChange = useCallback(() => {
    console.log('onChange triggered');
  }, []);

  const contextToShow: ContextMappingType[] = [
    {
      contextId: 'Context1',
      edgeEndpoint: 'Live',
      personalizeTenant: 'SpinAir Production',
      searchTenant: 'SpinAir Prod',
    },
    {
      contextId: 'Context2',
      edgeEndpoint: 'Staging',
      personalizeTenant: 'SpinAir UAT',
      searchTenant: 'SpinAir UAT',
    },
  ];

  return (
    <>
      <Flex direction='column'>
        <Header>
          <Heading as='h1'>
            <T _str='Context Mapping' />
          </Heading>
          <ButtonGroup>
            <Feature name='CONTEXT_MAPPING'>
              <Button
                variant='solid'
                onClick={() =>
                  history.push({
                    pathname: ctxMappingCreateRoute,
                    search: history.location.search,
                  })
                }
                leftIcon={<PortalIcon path={mdiPlus} />}
                data-testid='add-context-mapping-toggle'
                data-behavior-analytics-id='Add context mapping button - Context Mapping page'
              >
                <T _str='Add context mapping' />
              </Button>
            </Feature>
          </ButtonGroup>
        </Header>
        <div>
          <DataTable
            columns={options.columns}
            //@ts-ignore
            rowActions={options.rowActions}
            onChange={onChange}
            data={contextToShow}
            theaderProps={{ 'data-behavior-analytics-id': 'contexts sorting' }}
            pagination='hidden'
            data-behavior-analytics-feature='contexts table'
          />
        </div>
      </Flex>
      <ContextMappingDrawerRoute
        exact
        path={ctxMappingCreateRoute}
        component={AddEditContextMappingDrawer}
      />
      <ContextMappingDrawerRoute
        exact
        path={getCtxMappingEditRoute()}
        component={AddEditContextMappingDrawer}
      />
    </>
  );
};
